import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import './home.css';

import electricTourLogo from './../../assets/images/website-logo.svg';
import electricTourLogoSmall from './../../assets/images/website-logo-small.svg';
import linkedinLogo from './../../assets/images/linkedin-logo.png';
import christianGernemannProfilePic from './../../assets/images/profile-pictures/Christian-Gernemann.jpg';
import antoniePopovicProfilePic from './../../assets/images/profile-pictures/Antonie-Popovic.png';
import razvanOlaruProfilePic from './../../assets/images/profile-pictures/Razvan-Olaru.jpg';
import alexPopProfilePic from './../../assets/images/profile-pictures/Alex-Pop.jpg'

export default function HomePage() {
  const params = useParams();
  const navigate = useNavigate();
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);

  const sectionRefs = {
    home: useRef(null),
    team: useRef(null)
  };

  useEffect(() => {
    const { section } = params;

    if (section) {
      if (sectionRefs[section]) {
        navigateToSection(section);
      } else {
        navigate('/');
      }
    } else {
      navigateToSection('home');
    }

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, [params]);

  const onWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const navigateToSection = section => {
    sectionRefs[section].current.scrollIntoView();
  }

  return (
    <div className="home-page">
      <header>
        <h1><img alt="Electric Tour" src={screenWidth < 560 ? electricTourLogoSmall : electricTourLogo} /></h1>
        <nav>
          <ul>
            <li><Link to="/">Prezentare</Link></li>
            <li><Link to="/team">Echipa</Link></li>
          </ul>
        </nav>
      </header>
      <div ref={sectionRefs.home} className="home-page__hero-section">
        <div className="home-page__hero-section--left">
          <p>
            <img className="hero-section-christian" alt="Christian Gernemann" src={christianGernemannProfilePic} />
            Salutare! Sunt Christian Gernemann și vă prezint România văzută prin ochii mei, în cea mai lungă călătorie făcută cu o mașină electrică în țară: 26.000 km.
            <br />
            <br />
            Pentru că vreau să susțin energia regenerabilă și să păstram România cât mai verde.
          </p>
        </div>
        <iframe
          className="home-page__hero-section--right"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/yYG3zYh1Fhw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </div>
      <div ref={sectionRefs.team} className="team">
        <h2>Echipa</h2>
        <p className="team__description">
          Energie regenerabilă, mașini electrice, soluții tehnologice eco-friendly, acestea sunt câteva din ariile de care este pasionată echipa Electric Tour.
          Odată ce l-am întâlnit pe Christian am fost fascinați de modul lui inedit de a promova aceste domenii și i-am venit în ajutor realizând platforma <a target="_blank" rel="noreferrer" href="https://www.wenwere.com/app">WenWere</a>:
          o suită de aplicații pentru realizarea itinerariilor, navigație, tracking de locație în timp
          real și alerte în cazul devierii de la ruta inițială (geofencing).
        </p>
        <div className="team-members">
          <div className="team-members__column">
            <a className="team-members__column__linkedin" alt="Click to view LinkedIn profile" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/christian-g-280b8599/">
              <span className="team-members__column__profile-picture">
                  <img alt="Christian Gernemann" src={christianGernemannProfilePic} />
              </span>
              <h5 className="centered"><img alt="Christian Gernemann LinkedIn profile" src={linkedinLogo} /><span>Christian Gernemann</span></h5>
            </a>
            <ul>
              <li>Șofer, eroul Electric Tour</li>
              <li>Agent de vânzări</li>
              <li>Pasionat de tot ce este legat de energia regenerabilă.</li>
            </ul>
          </div>
          <div className="team-members__column">
              <a className="team-members__column__linkedin" alt="Click to view LinkedIn profile" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/antonie-popovic/">
                  <span className="team-members__column__profile-picture">
                      <img alt="Antonie Popović" src={antoniePopovicProfilePic} />
                  </span>
                  <h5 className="centered"><img alt="Antonie Popović LinkedIn profile" src={linkedinLogo} /><span>Antonie Popović</span></h5>
              </a>
              <ul>
                  <li>IT Systems Manager</li>
                  <li>Infrastructure Engineer</li>
                  <li>Suport în organizarea traseelor, găsirea locurilor de cazare și surselor de alimentare si colectarea datelor de pe traseu (foto, video, GPS Tracking).</li>
              </ul>
          </div>
          <div className="team-members__column">
              <a className="team-members__column__linkedin" alt="Click to view LinkedIn profile" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/olaru-razvan-catalin-83132965/">
                  <span className="team-members__column__profile-picture">
                      <img alt="Răzvan Olaru" src={razvanOlaruProfilePic} />
                  </span>
                  <h5 className="centered"><img alt="Răzvan Olaru LinkedIn profile" src={linkedinLogo} /><span>Răzvan Olaru</span></h5>
              </a>
              <ul>
                  <li>Full Stack mobile &amp; web developer (Node.js, Cordova, Ionic, React și Angular)</li>
                  <li>Infrastructură</li>
                  <li>Suport tehnic</li>
                  <li>UI/UX</li>
              </ul>
          </div>
          <div className="team-members__column">
              <a className="team-members__column__linkedin" alt="Click to view LinkedIn profile" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/pop-alexandru-911b40a5/">
                  <span className="team-members__column__profile-picture">
                      <img alt="Alexandru Pop" src={alexPopProfilePic} />
                  </span>
                  <h5 className="centered"><img alt="Alexandru Pop LinkedIn profile" src={linkedinLogo} /><span>Alexandru Pop</span></h5>
              </a>
              <ul>
                  <li>Mobile Native Applications Developer (Android / Java)</li>
                  <li>Suport tehnic</li>
                  <li>UI/UX</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
};